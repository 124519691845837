import { createUseI18n } from 'keycloakify/login'
import { Locales } from './types'
import { messages } from './login/i18n'

type Props = {
  locales?: Locales
}

export function createI18n(props: Props) {
  const { locales } = props
  const extraMessages = locales !== undefined ? locales : {}
  let combinedMessages = { ...extraMessages }
  Object.keys(addedLanguages).map((languageTag) => {
    return (combinedMessages[languageTag] = { ...((addedLanguages as any)[languageTag] ?? {}), ...(extraMessages[languageTag] ?? {}) })
  })
  Object.keys(extraMessages).map((languageTag) => {
    if ((addedLanguages as any)[languageTag] === undefined) {
      combinedMessages[languageTag] = { ...((messages as any)[languageTag] ?? {}), ...(extraMessages[languageTag] ?? {}) }
    }
    return []
  })
  return createUseI18n({ ...messages, ...combinedMessages })
}

export type I18n = NonNullable<ReturnType<typeof createI18n>>

const addedLanguages = {
  fo: {
    /* spell-checker: disable */
    welcomeTo: '',
    resetPassword: 'Nulstilla loyniorð',
    recoverPasswordSuccess: 'Umbønin um at fáa loyniorðið aftur er send',
    recoverPasswordEmail: 'Fekst tú ikki teldupost?',
    recoverPasswordSpam: 'Kanna spam/junk',
    recoverPasswordOr: 'ella',
    recoverPasswordTry: 'royn annan teldupost',
    recoverBacktoSign: 'Far aftur til innritan',
    locale_fo: 'Føroyskt',
    doLogin: 'Rita inn',
    doLogIn: 'Rita inn',
    doRegister: 'Skráset',
    doCancel: 'Ógilda',
    doSubmit: 'Lat inn',
    doBack: 'Aftur',
    doYes: 'Ja',
    doNo: 'Nei',
    doContinue: 'Halt fram',
    doIgnore: 'Ignorera',
    doAccept: 'Svara',
    doDecline: 'Avvís',
    doForgotPassword: 'Gloymt loyniorð?',
    doClickHere: 'Trýst her',
    doImpersonate: 'Persóngera',
    doTryAgain: 'Royn umaftur',
    doTryAnotherWay: 'Royn annan hátt',
    doConfirmDelete: 'Vátta striking',
    errorDeletingAccount: 'Villa íkomin, meðan kontan varð strikað',
    deletingAccountForbidden: 'Tú hevur ikki loyvi at strika tína egnu kontu. Set teg í samband við fyrisitara.',
    kerberosNotConfigured: 'Kerberos ikki uppsett',
    kerberosNotConfiguredTitle: 'Kerberos ikki uppsett',
    bypassKerberosDetail: 'Annaðhvørt ert tú ritað/ur inn av Kerberos, ella er tín brovsari ikki settur upp til Kerberos-innritan. Vinaliga trýst á halt fram fyri at rita inn.',
    kerberosNotSetUp: 'Kerberos er ikki sett upp. Ber ikki til at rita inn.',
    registerTitle: 'Skráset',
    loginAccountTitle: 'Rita inn á tína kontu',
    loginTitle: 'Rita inn á {0}',
    impersonateTitle: '{0} Persónger brúkara',
    impersonateTitleHtml: '<strong>{0}</strong> Persónger brúkara',
    realmChoice: 'Øki',
    unknownUser: 'Ókendur brúkari',
    loginTotpTitle: 'Mobile Authenticator-uppseting',
    loginProfileTitle: 'Dagfør kontuupplýsing',
    loginIdpReviewProfileTitle: 'Dagfør kontuupplýsing',
    loginTimeout: 'Tín innritan breyt av. Innritan byrjar av nýggjum.',
    reauthenticate: 'Vinaliga vátta av nýggjum fyri at halda fram',
    oauthGrantTitle: 'Játta atgongd til {0}',
    oauthGrantTitleHtml: '{0}',
    oauthGrantInformation: 'Tryggja tær, at tú hevur álit á {0} við at læra, hvussu {0} handfer títt data.',
    oauthGrantReview: 'Tú kanst kanna',
    oauthGrantTos: 'tænastutreytir.',
    oauthGrantPolicy: 'privatlívspolitikk.',
    errorTitle: 'Tað harmar okkum...',
    errorTitleHtml: 'Vit eru <strong>kedd</strong> ...',
    emailVerifyTitle: 'Teldupostváttan',
    emailForgotTitle: 'Hevur tú gloymt loyniorðið?',
    updateEmailTitle: 'Dagfør teldupost',
    emailUpdateConfirmationSentTitle: 'Váttanarteldupostur sendur',
    emailUpdateConfirmationSent: 'Ein váttanarteldupostur er sendur til {0}. Fylg vegleiðingini fyri at enda dagføringina.',
    emailUpdatedTitle: 'Teldupostur dagførdur',
    emailUpdated: 'Kontutelduposturin er dagførdur til {0}.',
    updatePasswordTitle: 'Dagfør loyniorð',
    codeSuccessTitle: 'Góð kota',
    codeErrorTitle: 'Villukota: {0}',
    displayUnsupported: 'Umbøn um skíggjaslag ikki stuðlað',
    browserRequired: 'Brovsari neyðugur at rita inn',
    browserContinue: 'Brovsari neyðugur fyri at fullfíggja innritan',
    browserContinuePrompt: 'Lata brovsara upp og halda fram við innritan? [y/n]:',
    browserContinueAnswer: 'y',
    usb: 'USB',
    nfc: 'NFC',
    bluetooth: 'Bluetooth',
    internal: 'Innanhýsis',
    unknown: 'Ókent',
    termsTitle: 'Treytir og viðurskifti',
    termsText: '<p>Treytir og viðurskifti at verða allýst</p>',
    termsPlainText: 'Treytir og viðurskifti at verða allýst',
    recaptchaFailed: 'Ógildigt Recaptcha',
    recaptchaNotConfigured: 'Recaptcha er neyðugt, men ikki uppsett',
    consentDenied: 'Samtykki noktað.',
    noAccount: 'Nýggjur brúkari?',
    username: 'Brúkaranavn',
    usernameOrEmail: 'Brúkaranavn ella teldupostur',
    firstName: 'Fornavn',
    givenName: 'Fornavn',
    fullName: 'Fult navn',
    lastName: 'Eftirnavn',
    familyName: 'Eftirnavn',
    email: 'Teldupostur',
    password: 'Loyniorð',
    passwordConfirm: 'Vátta loyniorð',
    passwordNew: 'Nýtt loyniorð',
    passwordNewConfirm: 'Nýtt loyniorð-váttan',
    rememberMe: 'Minst til mín',
    authenticatorCode: 'Stakkota',
    address: 'Bústaður',
    street: 'Gøta',
    locality: 'Býur ella stað',
    region: 'Statur, landslutur ella øki',
    postal_code: 'Postnummar',
    country: 'Land',
    emailVerified: 'Teldupostur váttaður',
    website: 'Heimasíða',
    phoneNumber: 'Telefonnummar',
    phoneNumberVerified: 'Telefonnummar váttað',
    gender: 'Kyn',
    birthday: 'Føðingardagur',
    zoneinfo: 'Tíðarøki',
    gssDelegationCredential: 'GSS Delegation Credential',
    logoutOtherSessions: 'Rita út úr øðrum tólum',
    profileScopeConsentText: 'Vangamynd',
    emailScopeConsentText: 'Teldupostur',
    addressScopeConsentText: 'Bústaður',
    phoneScopeConsentText: 'Telefonnummar',
    offlineAccessScopeConsentText: 'Offline atgongd',
    samlRoleListScopeConsentText: 'Mínir leiklutir',
    rolesScopeConsentText: 'Brúkaraleiklutir',
    restartLoginTooltip: 'Byrja innritan av nýggjum',
    loginTotpIntro: 'Tú mást seta ein stakloyniorðgenerator upp fyri at fáa atgongd til hesa kontu',
    loginTotpStep1: 'Installera eina av fylgjandi appum á tína fartelefon:',
    loginTotpStep2: 'Lat appina upp og skanna stangarkotuna:',
    loginTotpStep3: 'Skriva stakkotuna, sum er útvegað á appini og trýst Send inn fyri at enda uppsetingina.',
    loginTotpStep3DeviceName: 'Áset tólnavn, so tú hevur stýr á tínum OTP-tólum.',
    loginTotpManualStep2: 'Lat appina upp og skriva lykilin:',
    loginTotpManualStep3: 'Brúka fylgjandi uppsetingarvirði, um appina loyvir tær at brúka tær:',
    loginTotpUnableToScan: 'Ikki ført fyri at skanna?',
    loginTotpScanBarcode: 'Skanna stangarkotu?',
    loginCredential: 'Umboðsskjal',
    loginOtpOneTime: 'Stakkota',
    loginTotpType: 'Slag',
    loginTotpAlgorithm: 'Algoritma',
    loginTotpDigits: 'Tøl',
    loginTotpInterval: 'Tíðarbil',
    loginTotpCounter: 'Teljari',
    loginTotpDeviceName: 'Tólnavn',
    'loginTotp.totp': 'Tíðargrundað',
    'loginTotp.hotp': 'Teljaragrundað',
    totpAppFreeOTPName: 'FreeOTP',
    totpAppGoogleName: 'Google Authenticator',
    loginChooseAuthenticator: 'Vel inritingarhátt',
    oauthGrantRequest: 'Do you grant these access privileges?',
    inResource: 'inn',
    oauth2DeviceVerificationTitle: 'Tólinnritan',
    verifyOAuth2DeviceUserCode: 'Skriva kotuna, sum tólið hevur útvegað tær og trýst á Send inn',
    oauth2DeviceInvalidUserCodeMessage: 'Ógildig kota, vinaliga royn aftur.',
    oauth2DeviceExpiredUserCodeMessage: 'Kotan er farin úr gildi. Vinaliga far aftur til títt tól og royn at fáa samband aftur.',
    oauth2DeviceVerificationCompleteHeader: 'Tólinnritan væleydnað',
    oauth2DeviceVerificationCompleteMessage: 'Tú kanst lata brovsaravindeygað aftur og fara aftur til títt tól.',
    oauth2DeviceVerificationFailedHeader: 'Tólinnritan miseydnaðist',
    oauth2DeviceVerificationFailedMessage: 'Tú kanst lata hetta brovsaravindeygað aftur og fara aftur til títt tól og royna at fáa samband aftur.',
    oauth2DeviceConsentDeniedMessage: 'Samtykki noktað fyri at konnekta hetta tól.',
    oauth2DeviceAuthorizationGrantDisabledMessage: 'Klientur er ikki loyvi at seta OAuth 2.0 Device Authorization Grant í verk.',
    emailVerifyInstruction1: 'Ein teldupostur við vegleiðing um, hvussu tú váttar teldupostin, er sendur til tín {0}',
    emailVerifyInstruction2: 'Ikki fingið váttanarkotu við telduposti?',
    emailVerifyInstruction3: 'til send teldupost umaftur.',
    emailLinkIdpTitle: 'Leinki {0}',
    emailLinkIdp1: 'Ein teldupostur við vegleiðing til leinki {0} konta {1} við tínari {2} kontu er sent til tín.',
    emailLinkIdp2: 'Ikki fingið váttanarkotu við telduposti?',
    emailLinkIdp3: 'til send teldupost umaftur.',
    emailLinkIdp4: 'Um tú longu hevur váttað teldupostin í øðrum brovsara',
    emailLinkIdp5: 'til halda fram.',
    backToLogin: '&laquo; Aftur til innritan',
    emailInstruction: 'Skriva brúkaranavn ella teldupost, og vit senda tær vegleiðing um, hvussu tú stovnar nýtt loyniorð.',
    emailInstructionUsername: 'Skriva brúkaranavn, og vit senda tær vegleiðing um, hvussu tú stovnar nýtt loyniorð.',
    copyCodeInstruction: 'Vinaliga avrita hesa kotu og set hana inn í appina:',
    pageExpiredTitle: 'Síðan er farin úr gildi',
    pageExpiredMsg1: 'Fyri at endurbyrja innritan',
    pageExpiredMsg2: 'Fyri at halda fram við innritan',
    personalInfo: 'Persónliga upplýsingar:',
    role_admin: 'Fyrisitari',
    'role_realm-admin': 'Økisfyrisitari',
    'role_create-realm': 'Stovna øki',
    'role_create-client': 'Stovna klient',
    'role_view-realm': 'Síggj øki',
    'role_view-users': 'Síggj brúkarar',
    'role_view-applications': 'Síggj appir',
    'role_view-clients': 'Síggj klientar',
    'role_view-events': 'Síggj hendingar',
    'role_view-identity-providers': 'Síggj identity providers',
    'role_manage-realm': 'Stýr øki',
    'role_manage-users': 'Stýr brúkarum',
    'role_manage-applications': 'Stýr appum',
    'role_manage-identity-providers': 'Stýr identity providers',
    'role_manage-clients': 'Stýr klientum',
    'role_manage-events': 'Stýr hendingum',
    'role_view-profile': 'Hygg at vanga',
    'role_manage-account': 'Stýr kontu',
    'role_manage-account-links': 'Stýr kontuleinki',
    'role_read-token': 'Les tekin',
    'role_offline-access': 'Offline atgongd',
    client_account: 'Konta',
    'client_account-console': 'Kontustýristøð',
    'client_security-admin-console': 'Trygdarafyrisitarastýristøð',
    'client_admin-cli': 'Fyrisitara-CLI',
    'client_realm-management': 'Økisleiðsla',
    client_broker: 'Meklari',
    requiredFields: 'Neyðug øki',
    invalidUserMessage: 'Ógildigt brúkaranavn ella loyniorð.',
    invalidUsernameMessage: 'Ógildigt brúkaranavn.',
    invalidUsernameOrEmailMessage: 'Ógildigt brúkaranavn ella teldupostur.',
    invalidPasswordMessage: 'Ógildigt loyniorð.',
    invalidEmailMessage: 'Ógildigur teldupostur.',
    accountDisabledMessage: 'Konta er óvirkjað. Set teg í samband við fyrisitara.',
    accountTemporarilyDisabledMessage: 'Konta er fyribils óvirkjað. Set teg í samband við fyrisitara ella royn aftur seinni.',
    expiredCodeMessage: 'Innritan avbrotin. Vinaliga rita inn aftur.',
    expiredActionMessage: 'Gerð endað. Vinaliga halt fram við innritan nú.',
    expiredActionTokenNoSessionMessage: 'Ger endað.',
    expiredActionTokenSessionExistsMessage: 'Gerð endað. Vinaliga byrja aftur.',
    sessionLimitExceeded: 'Ov nógvar sessiónir',
    missingFirstNameMessage: 'Vinaliga áset fornavn.',
    missingLastNameMessage: 'Vinaliga áset eftirnavn.',
    missingEmailMessage: 'Vinaliga áset teldupost.',
    missingUsernameMessage: 'Vinaliga áset brúkaranavn.',
    missingPasswordMessage: 'Vinaliga áset loyniorð.',
    missingTotpMessage: 'Vinaliga áset samgildiskotu.',
    missingTotpDeviceNameMessage: 'Vinaliga áset tólnavn.',
    notMatchPasswordMessage: 'Loyniorð samsvara ikki.',
    'error-invalid-value': 'Ógildigt virði.',
    'error-invalid-blank': 'Vinaliga áset virði.',
    'error-empty': 'Vinaliga áset virði.',
    'error-invalid-length': 'Longd má vera millum {1} og {2}.',
    'error-invalid-length-too-short': 'Minstalongd er {1}.',
    'error-invalid-length-too-long': 'Hámarkslongd er {2}.',
    'error-invalid-email': 'Ógildigur teldupostur.',
    'error-invalid-number': 'Ógildigt nummar',
    'error-number-out-of-range': 'Nummar má vera millum {1} og {2}.',
    'error-number-out-of-range-too-small': 'Nummar má hava minsta virði av {1}.',
    'error-number-out-of-range-too-big': 'Nummar má hava hámarksvirði av {2}.',
    'error-pattern-no-match': 'Ógildigt virði.',
    'error-invalid-uri': 'Ógildig URL.',
    'error-invalid-uri-scheme': 'Ógildig URL-skipan.',
    'error-invalid-uri-fragment': 'Ógildigt URL-brot.',
    'error-user-attribute-required': 'Vinaliga áset hetta økið.',
    'error-invalid-date': 'Ógildig dagfesting.',
    'error-user-attribute-read-only': 'Hetta øki er bara lesnaður.',
    'error-username-invalid-character': 'Virði inniheldur ógildigan stav.',
    'error-person-name-invalid-character': 'Virði inniheldur ógildigan stav.',
    invalidPasswordExistingMessage: 'Ógildigt endaloyniorð.',
    invalidPasswordBlacklistedMessage: 'Ógildigt loyniorð: loyniorðið er sett á svartalista.',
    invalidPasswordConfirmMessage: 'Loyniorðváttan samsvarar ikki.',
    invalidTotpMessage: 'Ógildig samgildiskota.',
    usernameExistsMessage: 'Brúkaranavn er longu brúkt.',
    emailExistsMessage: 'Teldupostur longu brúktur.',
    federatedIdentityExistsMessage: 'Brúkari við {0} {1} finst longu. Vinaliga rita inn á kontuleiðslu fyri at samanbinda konturnar.',
    federatedIdentityUnavailableMessage: 'Brúkari {0} váttað við identitetsútvegara {1} finst ikke. Vinaliga set teg í samband við fyrisitara.',
    confirmLinkIdpTitle: 'Konta finst longu',
    federatedIdentityConfirmLinkMessage: 'Brúkari við {0} {1} finst longu. Hvussu vilt tú halda fram?',
    federatedIdentityConfirmReauthenticateMessage: 'Vátta fyri at leinkja tína kontu við {0}',
    nestedFirstBrokerFlowMessage: '{0} brúkari {1} er ikki leinkjað til nakran kendan brúkara.',
    confirmLinkIdpReviewProfile: 'Kanna vanga',
    confirmLinkIdpContinue: 'Legg afturat verandi kontu',
    configureTotpMessage: 'Tú mást seta Mobile Authenticator upp fyri at virka tína kontu.',
    configureBackupCodesMessage: 'Tú mást seta Backup Codes upp fyri at virka tína kontu.',
    updateProfileMessage: 'Tú mást dagføra tína vangamynd fyri at virka tína kontu.',
    updatePasswordMessage: 'Tú mást broyt títt loyniorð fyri at virka tína kontu.',
    updateEmailMessage: 'Tú mást dagføra tína teldupost fyri at virka tína kontu.',
    resetPasswordMessage: 'Tú mást broyta loyniorð.',
    verifyEmailMessage: 'Tú mást vátta tín teldupost fyri at virka tína kontu.',
    linkIdpMessage: 'Tú mást vátta tín teldupost fyri at leinkja tín kontu við {0}.',
    emailSentMessage: 'Um eina løtu burdi tú fingið teldupost við meira vegleiðing.',
    emailSendErrorMessage: 'Riggaði ikki at senda teldupost, vinaliga royn aftur seinni.',
    accountUpdatedMessage: 'Tín konta er dagførd.',
    accountPasswordUpdatedMessage: 'Títt loyniorð er dagført.',
    delegationCompleteHeader: 'Innritan eydnaðist',
    delegationCompleteMessage: 'Tú kanst lata hetta brovsaravindeyga aftur og fara aftur til tína stýristøðapp.',
    delegationFailedHeader: 'Innritan miseydnaðist',
    delegationFailedMessage: 'Tú kanst lata hetta brovsaravindeygað aftur og fara aftur til tína stýristøðapp og royna at rita inn aftur.',
    noAccessMessage: 'Eingin atgongd',
    invalidPasswordMinLengthMessage: 'Ógildigt loyniorð: Minstalongd {0}.',
    invalidPasswordMaxLengthMessage: 'Ógildigt loyniorð: Hámarkslongd {0}.',
    invalidPasswordMinDigitsMessage: 'Ógildigt loyniorð: má innihalda í minsta lagi {0} taltekn.',
    invalidPasswordMinLowerCaseCharsMessage: 'Ógildigt loyniorð: má innihalda í minsta lagi {0} smáar bókstavir.',
    invalidPasswordMinUpperCaseCharsMessage: 'Ógildigt loyniorð: má innihalda í minsta lagi {0} stórar bókstavir.',
    invalidPasswordMinSpecialCharsMessage: 'Ógildigt loyniorð: má innihalda í minsta lagi {0} sermerki.',
    invalidPasswordNotUsernameMessage: 'Ógildigt loyniorð: má ikki vera líka sum brúkaranavnið.',
    invalidPasswordNotEmailMessage: 'Ógildigt loyniorð: má ikki vera líka sum telduposturin.',
    invalidPasswordRegexPatternMessage: 'Ógildigt loyniorð: miseydnaðist at samsvara regex-mynstur.',
    invalidPasswordHistoryMessage: 'Ógildigt loyniorð: má ikki vera líka sum nakað av teimum seinastu {0} loyniorðunum.',
    invalidPasswordGenericMessage: 'Ógildigt loyniorð: nýggja loyniorðið samsvarar ikki við loyniorðpolitikkin.',
    failedToProcessResponseMessage: 'Failed to process response',
    httpsRequiredMessage: 'HTTPS neyðugt',
    realmNotEnabledMessage: 'Øki ikki virkja',
    invalidRequestMessage: 'Ógildig umbøn',
    successLogout: 'Tú ert ritað/ur út',
    failedLogout: 'Útritan miseydnaðist',
    unknownLoginRequesterMessage: 'Ókent innritanarumbøn',
    loginRequesterNotEnabledMessage: 'Innritanarumbøn ikki virkjað.',
    bearerOnlyMessage: 'Bearer-only applications are not allowed to initiate browser login',
    standardFlowDisabledMessage: 'Klienturin hevur ikki loyvi at byrja brovsarainnritan við givnu response_type. Standard flow er óvirkjað fyri klientin.',
    implicitFlowDisabledMessage: 'Klienturin hevur ikki loyvi at byrja brovsarainnritan við givnu response_type. Implicit flow er óvirkjað fyri klientin.',
    invalidRedirectUriMessage: 'Ógildig URI-umlegging',
    unsupportedNameIdFormatMessage: 'Ikki stuðlað navneyðmerkjasnið',
    invalidRequesterMessage: 'Ógildigur umbønari',
    registrationNotAllowedMessage: 'Skráseting ikki loyvd',
    resetCredentialNotAllowedMessage: 'Nullstilla umboðsskjal ikki loyvd',
    permissionNotApprovedMessage: 'Loyvi ikki samtykt',
    noRelayStateInResponseMessage: 'No relay state in response from identity provider.',
    insufficientPermissionMessage: 'Ikki nóg gott loyvi til leinki-identitet.',
    couldNotProceedWithAuthenticationRequestMessage: 'Kundi ikki halda fram við samgildisumbøn til identitsútvegara.',
    couldNotObtainTokenMessage: 'Kundi ikki útvega tekin til identitetsútvegara.',
    unexpectedErrorRetrievingTokenMessage: 'Óvæntað villa í royndini at heinta tekin frá identitetsútvegara.',
    unexpectedErrorHandlingResponseMessage: 'Óvæntað villa í royndini at handfara svar frá identitetsútvegara.',
    identityProviderAuthenticationFailedMessage: 'Samgildi miseydnaðist. Kundi ikki vátta við identitetsútvegara.',
    couldNotSendAuthenticationRequestMessage: 'Kund ikki senda samgildisumbøn til identitetsútvegara.',
    unexpectedErrorHandlingRequestMessage: 'Óvæntað villa í royndini at handfara samgildisumbøn til identitetsútvegara.',
    invalidAccessCodeMessage: 'Ógild atgongdarkota.',
    sessionNotActiveMessage: 'Sessión ikki virkin.',
    invalidCodeMessage: 'Villa íkomin. Vinaliga rita inn aftur gjøgnum tína skipan.',
    cookieNotFoundMessage: 'Cookie ikki funnið. Vinaliga tryggja tær, at cookies eru virkjaðar í tínum brovsara.',
    insufficientLevelOfAuthentication: 'Samgildisstigið, sum er umbiðið, er ikki lúkað.',
    identityProviderUnexpectedErrorMessage: 'Óvænta villa í royndini at vátta við identitetsútvegara.',
    identityProviderMissingStateMessage: 'Vantar støðuviðurskifti sum svar frá identitetsútvegara.',
    identityProviderInvalidResponseMessage: 'Ógildað svar frá identitetsútvegara.',
    identityProviderInvalidSignatureMessage: 'Ógildig undirskrift sum svar frá identitetsútvegara.',
    identityProviderNotFoundMessage: 'Kund ikki finna ein identitetsútvegara í dátuheitinum.',
    identityProviderLinkSuccess: 'Væleydnað váttan av tínum telduposti. Far vinaliga aftur til upprunaliga brovsaran og halt fram við innritan.',
    staleCodeMessage: 'Hendan síðan er ikki longur gildig. Vinaliga far aftur til tína nýtsluskipan og rita inn aftur.',
    realmSupportsNoCredentialsMessage: 'Øki stuðlar ikki nøkrum slagi av umboðsskjali.',
    credentialSetupRequired: 'Kann ikki rita inn. Umboðsskjalaruppseting neyðug.',
    identityProviderNotUniqueMessage: 'Øki stuðlar fleiri identitetsútvegaran. Kann ikki gera av, hvør identitetsútvegara skal brúkast',
    emailVerifiedMessage: 'Tín teldupostur er váttaður.',
    staleEmailVerificationLink: 'Leinki, sum tú trýsti á, er eitt gamalt leinki og er ikki longur gildigt. Kanska hevur tú longu váttað tín teldupost.',
    identityProviderAlreadyLinkedMessage: 'Federated identity returned by {0} is already linked to another user.',
    confirmAccountLinking: 'Vátta at leinkja kontu {0} til identitetsútvegara {1} við tínari kontu.',
    confirmEmailAddressVerification: 'Vátta gildið av telduposti {0}.',
    confirmExecutionOfActions: 'Ger fylgjandi gerð',
    locale_ca: 'Català',
    locale_cs: 'Čeština',
    locale_da: 'Dansk',
    locale_de: 'Deutsch',
    locale_en: 'English',
    locale_es: 'Español',
    locale_fr: 'Français',
    locale_hu: 'Magyar',
    locale_it: 'Italiano',
    locale_ja: '日本語',
    locale_lt: 'Lietuvių',
    locale_lv: 'Latviešu',
    locale_nl: 'Nederlands',
    locale_no: 'Norsk',
    locale_pl: 'Polski',
    locale_pt_BR: 'Português (Brasil)',
    locale_ru: 'Русский',
    locale_sk: 'Slovenčina',
    locale_sv: 'Svenska',
    locale_tr: 'Türkçe',
    'locale_zh-CN': '中文简体',
    locale_fi: 'Suomi',
    backToApplication: '&laquo; Aftur til skipan',
    missingParameterMessage: 'Vantandi viðuskifti: {0}',
    clientNotFoundMessage: 'Klientur ikki funnin.',
    clientDisabledMessage: 'Klientur óvirkjaður.',
    invalidParameterMessage: 'Ógildað viðurskifti: {0}',
    alreadyLoggedIn: 'Tú ert longu ritað/ur inn.',
    differentUserAuthenticated: "Tú ert longu váttað/ur sum annar brúkari ''{0}'' í hesi sessión. Vinaliga rita út fyrst.",
    brokerLinkingSessionExpired: 'Requested broker account linking, but current session is no longer valid.',
    proceedWithAction: '&raquo; Trýst her fyri at halda fram',
    acrNotFulfilled: 'Samgildiskrav ikki fullgjørt.',
    'requiredAction.CONFIGURE_TOTP': 'Stilla OTP',
    'requiredAction.terms_and_conditions': 'Treytir og viðurskifti',
    'requiredAction.UPDATE_PASSWORD': 'Dagfør loyniorð',
    'requiredAction.UPDATE_PROFILE': 'Dagfør vanga',
    'requiredAction.VERIFY_EMAIL': 'Vátta teldupost',
    'requiredAction.CONFIGURE_RECOVERY_AUTHN_CODES': 'Ger endurgerðarkotur',
    'requiredAction.webauthn-register-passwordless': 'Webauthn Register Passwordless',
    doX509Login: 'Tú verður ritað/ur inn sum:',
    clientCertificate: 'X509 klientprógv:',
    noCertificate: '[Einki prógv]',
    pageNotFound: 'Síða ikki funnin',
    internalServerError: 'Ein innanhýsis villa er íkomin',
    'console-username': 'Brúkaranavn:',
    'console-password': 'Loyniorð:',
    'console-otp': 'Stakloyniorð:',
    'console-new-password': 'Nýtt loyniorð:',
    'console-confirm-password': 'Vátta loyniorð:',
    'console-update-password': 'Dagføring av loyniorð er neyðug.',
    'console-verify-email': 'Tú mást vátta tín teldupost. Vit hava tær ein teldupost, sum inniheldur eina váttanarkotu, til {0}. Vinaliga skriva kotuna í inntakið niðanfyri.',
    'console-email-code': 'Teldupostkota:',
    'console-accept-terms': 'Játta treytir? [y/n]:',
    'console-accept': 'y',
    'openshift.scope.user_info': 'Brúkaraupplýsingar',
    'openshift.scope.user_check-access': 'Upplýsingar um brúkaraatgongd',
    'openshift.scope.user_full': 'Fulla atgongd',
    'openshift.scope.list-projects': 'Verkætlanarlisti',
    'saml.post-form.title': 'Samgildisumlegging',
    'saml.post-form.message': 'Umleggur, vinaliga bíða.',
    'saml.post-form.js-disabled': 'JacaScript er óvirkjað. Vit viðmæla staðiliga, at tú virkar tað. Trýst á knøttin niðan fyri fyri at halda fram.',
    'saml.artifactResolutionServiceInvalidResponse': 'Unable to resolve artifact.',
    'otp-display-name': 'Authenticator Application',
    'otp-help-text': 'Skriva eina váttanarkotu frá authenticator application.',
    'password-display-name': 'Loyniorð',
    'password-help-text': 'Rita inn við at skriva títt loyniorð.',
    'auth-username-form-display-name': 'Brúkaranavn',
    'auth-username-form-help-text': 'Byrja innritan við at skriva títt loyniorð.',
    'auth-username-password-form-display-name': 'Brúkaranavn og loyniorð',
    'auth-username-password-form-help-text': 'Rita inn við at skriva títt brúkaranavn og loyniorð.',
    'auth-recovery-authn-code-form-display-name': 'Recovery Authentication Code',
    'auth-recovery-authn-code-form-help-text': 'Skriva ein endurgjørda kotu frá einum undanfarnum gjørdum lista.',
    'auth-recovery-code-info-message': 'Skriva tilskilaðu endurgjørdu kotuna.',
    'auth-recovery-code-prompt': 'Skriva kotu #{0}',
    'auth-recovery-code-header': 'Rita inn við kotu',
    'recovery-codes-error-invalid': 'Skeiv kota',
    'recovery-code-config-header': 'Kotur',
    'recovery-code-config-warning-title': 'Hesar kotu koma ikki fram aftur, eftir at tú ert farin av hesi síðu',
    'recovery-code-config-warning-message': 'Tryggja tær, at tú printar, tekur niður ella avritar tær til ein loyniorð-lista fyri at goyma tær trygt. Um tú ógildar uppsetingina, verða hesar kotur strikaður av tínar kontu.',
    'recovery-codes-print': 'Prenta',
    'recovery-codes-download': 'Tak niður',
    'recovery-codes-copy': 'Avrita',
    'recovery-codes-copied': 'Avritað',
    'recovery-codes-confirmation-message': 'Eg havi goymt koturnar eitt trygt stað',
    'recovery-codes-action-complete': 'Fullfíggja uppseting',
    'recovery-codes-action-cancel': 'Ógilda uppseting',
    'recovery-codes-download-file-header': 'Goym koturnar eitt trygt stað.',
    'recovery-codes-download-file-description': 'Koturnar eru staknýtt loyniorð, sum loyva tær at rita inn á kontuna, um tú ikki hevur atgongd til tín samgildara.',
    'recovery-codes-download-file-date': 'Koturnar vórðu gjørdar',
    'recovery-codes-label-default': 'Kotur',
    'webauthn-display-name': 'Trygdarlykil',
    'webauthn-help-text': 'Brúk trygdarlykilin at rita inn við.',
    'webauthn-passwordless-display-name': 'Trygdarlykil',
    'webauthn-passwordless-help-text': 'Brúka trygdarlykilin at rita inn við uttan loyniorð.',
    'webauthn-login-title': 'Trygdarlyknainnritan',
    'webauthn-registration-title': 'Trygdarlyknaskráseting',
    'webauthn-available-authenticators': 'Trygdarlyklar til taks',
    'webauthn-unsupported-browser-text': 'WebAuthn er ikki stuðlað á hesum brovsara. Roynd eina aðra ella set teg í samband við fyrisitara.',
    'webauthn-doAuthenticate': 'Rita inn við trygdarlykli',
    'webauthn-createdAt-label': 'Stovnað',
    'webauthn-error-title': 'Trygdarlyklavilla',
    'webauthn-error-registration': 'Miseydnaðist at skráseta tín trygdarlykil. <br/> {0}',
    'webauthn-error-api-get': 'Riggaði ikki at vátta við trygdarlykli <br/> {0}',
    'webauthn-error-different-user': 'First authenticated user is not the one authenticated by the Security key.',
    'webauthn-error-auth-verification': 'Samgildisúrslitið av trygdarlykli er ógildigt. <br/> {0}',
    'webauthn-error-register-verification': 'Skrásetingarúrslit av trygdarlykli er ógildigt. <br/> {0}',
    'webauthn-error-user-not-found': 'Ókendur samgildisbrúkari av trygdarlykli.',
    'identity-provider-redirector': 'Konnekta við einum øðrum samgildisútvegara',
    'identity-provider-login-label': 'ella rita inn við',
    'idp-email-verification-display-name': 'Teldupostváttan',
    'idp-email-verification-help-text': 'Leinkja tína kontu við at góðkenna tín teldupost.',
    'idp-username-password-form-display-name': 'Brúkaranavn og loyniorð',
    'idp-username-password-form-help-text': 'Leinkja tína kontu við at rita inn.',
    finalDeletionConfirmation: 'Kontan kann ikki endurskapast, um tú strikar hana. Fyri at varðveita kontuna, trýst Ógilda.',
    irreversibleAction: 'Gerðin kann ikki broytast',
    deleteAccountConfirm: 'Strika kontuváttan',
    deletingImplies: 'Strikar tínar kontuteytir:',
    errasingData: 'Strikar allar tínar dátur',
    loggingOutImmediately: 'Ritar út alt fyri eitt',
    accountUnusable: 'Øll eftirfylgjandi nýtsla av skipanini verður ikki gjørlig við hesi kontu',
    userDeletedSuccessfully: 'Brúkarastriking væleydnað',
    'access-denied': 'Atgongd noktað',
    'frontchannel-logout.title': 'Ritar út',
    'frontchannel-logout.message': 'Tú ert ritað/ur út á fylgjandi appum',
    logoutConfirmTitle: 'Ritar út',
    logoutConfirmHeader: 'Vilt tú rita út?',
    doLogout: 'Útritan',
    readOnlyUsernameMessage: 'Tú kanst ikki dagføra títt brúkaranavn, av tí at tað bara er til at lesa.',
    /* spell-checker: enable */
  },
  'es-419': {
    /* spell-checker: disable */
    welcomeTo: 'Bienvenido a {0}',
    resetPassword: 'Restablecer contraseña',
    recoverPasswordSuccess: 'La solicitud para recuperar su contraseña ha sido enviada',
    recoverPasswordEmail: '¿No recibió el correo electrónico?',
    recoverPasswordSpam: 'Compruebe su filtro de spam',
    recoverPasswordOr: 'o',
    recoverPasswordTry: 'pruebe con otra dirección de correo electrónico',
    recoverBacktoSign: 'Volver para iniciar sesión',
    locale_fo: 'Feroés',
    doLogin: 'Registrarse',
    doLogIn: 'Registrarse',
    doRegister: 'Registrarse',
    doCancel: 'Cancelar',
    doSubmit: 'Enviar',
    doBack: 'Atrás',
    doYes: 'Sí',
    doNo: 'No',
    doContinue: 'Continuar',
    doIgnore: 'Ignorar',
    doAccept: 'Aceptar',
    doDecline: 'Declinar',
    doForgotPassword: '¿Olvidó su contraseña?',
    doClickHere: 'Haga clic aquí',
    doImpersonate: 'Suplantar',
    doTryAgain: 'Inténtelo de nuevo',
    doTryAnotherWay: 'Pruebe de otra manera',
    doConfirmDelete: 'Confirmar eliminación',
    errorDeletingAccount: 'Se produjo un error al eliminar la cuenta.',
    deletingAccountForbidden: 'No tiene suficientes permisos para eliminar su propia cuenta, comuníquese con el administrador.',
    kerberosNotConfigured: 'Kerberos no configurado',
    kerberosNotConfiguredTitle: 'Kerberos no configurado',
    bypassKerberosDetail: 'O no ha iniciado sesión en Kerberos o su navegador no está configurado para iniciar sesión en Kerberos. Haga clic en continuar para iniciar sesión por otros medios',
    kerberosNotSetUp: 'Kerberos no está configurado. No puede iniciar sesión.',
    registerTitle: 'Registrarse',
    loginAccountTitle: 'Iniciar sesión en su cuenta',
    loginTitle: 'Iniciar sesión en {0}',
    impersonateTitle: '{0} Suplantar usuario',
    impersonateTitleHtml: '<strong>{0}</strong> Suplantar usuario',
    realmChoice: 'Reino',
    unknownUser: 'Usuario desconocido',
    loginTotpTitle: 'Configuración del autenticador móvil',
    loginProfileTitle: 'Actualizar información de la cuenta',
    loginIdpReviewProfileTitle: 'Actualizar información de la cuenta',
    loginTimeout: 'Se agotó el tiempo de tiempo de su intento de inicio de sesión. El inicio de sesión comenzará desde el principio.',
    reauthenticate: 'Vuelva a autenticarse para continuar',
    oauthGrantTitle: 'Conceder acceso a {0}',
    oauthGrantTitleHtml: '{0}',
    oauthGrantInformation: 'Asegúrese de confiar en {0} sabiendo cómo {0} manejará sus datos.',
    oauthGrantReview: 'Podría revisar los ',
    oauthGrantTos: 'términos de servicio.',
    oauthGrantPolicy: 'política de privacidad.',
    errorTitle: 'Lo sentimos...',
    errorTitleHtml: 'Lo sentimos <strong></strong>...',
    emailVerifyTitle: 'Verificacion de email',
    emailForgotTitle: '¿Olvidó su contraseña?',
    updateEmailTitle: 'Actualizar correo electrónico',
    emailUpdateConfirmationSentTitle: 'Correo de confirmación enviado',
    emailUpdateConfirmationSent: 'Se ha enviado un correo electrónico de confirmación a {0}. Debes seguir las instrucciones del anterior para completar la actualización por correo electrónico.',
    emailUpdatedTitle: 'Correo electrónico actualizado',
    emailUpdated: 'El correo electrónico de la cuenta se actualizó correctamente a {0}.',
    updatePasswordTitle: 'Actualizar contraseña',
    codeSuccessTitle: 'Código de éxito',
    codeErrorTitle: 'Código de error: {0}',
    displayUnsupported: 'El tipo de visualización solicitado no es compatible',
    browserRequired: 'Se requiere navegador para iniciar sesión',
    browserContinue: 'Se requiere navegador para completar el inicio de sesión',
    browserContinuePrompt: '¿Abrir el navegador y continuar iniciando sesión? [s/n]:',
    browserContinueAnswer: 's',
    usb: 'USB',
    nfc: 'NFC',
    bluetooth: 'Bluetooth',
    internal: 'Interno',
    unknown: 'Desconocido',
    termsTitle: 'Términos y Condiciones',
    termsText: '<p>Términos y condiciones por definir</p>',
    termsPlainText: 'Términos y condiciones por definir.',
    recaptchaFailed: 'Recaptcha no válido',
    recaptchaNotConfigured: 'Se requiere recaptcha, pero no está configurado',
    consentDenied: 'Consentimiento denegado.',
    noAccount: '¿Nuevo Usuario?',
    username: 'Nombre de usuario',
    usernameOrEmail: 'Nombre de usuario o correo electrónico',
    firstName: 'Nombre',
    givenName: 'Nombre de pila',
    fullName: 'Nombre completo',
    lastName: 'Apellido',
    familyName: 'Apellido',
    email: 'Email',
    password: 'Contraseña',
    passwordConfirm: 'Confirmar contraseña',
    passwordNew: 'Nueva contraseña',
    passwordNewConfirm: 'Nueva confirmación de contraseña',
    rememberMe: 'Recordarme',
    authenticatorCode: 'Código de una sola vez',
    address: 'Dirección',
    street: 'Calle',
    locality: 'Ciudad o Localidad',
    region: 'Estado, Provincia o Región',
    postal_code: 'Zip o Código Postal',
    country: 'País',
    emailVerified: 'Correo Electrónico Verificado',
    website: 'Página web',
    phoneNumber: 'Número de teléfono',
    phoneNumberVerified: 'Número de teléfono verificado',
    gender: 'Género',
    birthday: 'Fecha de nacimiento',
    zoneinfo: 'Zona horaria',
    gssDelegationCredential: 'Credencial de delegación GSS',
    logoutOtherSessions: 'Cerrar sesión en otros dispositivos',
    profileScopeConsentText: 'Perfil del usuario',
    emailScopeConsentText: 'Dirección de correo electrónico',
    addressScopeConsentText: 'Dirección',
    phoneScopeConsentText: 'Número de teléfono',
    offlineAccessScopeConsentText: 'Acceso sin conexión',
    samlRoleListScopeConsentText: 'Mis roles',
    rolesScopeConsentText: 'Roles del usuario',
    restartLoginTooltip: 'Reiniciar inicio de sesión',
    loginTotpIntro: 'Necesita configurar un generador de contraseña única para acceder a esta cuenta',
    loginTotpStep1: 'Instale una de las siguientes aplicaciones en su móvil:',
    loginTotpStep2: 'Abra la aplicación y escanee el código de barras:',
    loginTotpStep3: 'Ingrese el código de un solo uso proporcionado por la aplicación y haga clic en Enviar para finalizar la configuración.',
    loginTotpStep3DeviceName: 'Proporcione un nombre de dispositivo para ayudarlo a administrar sus dispositivos OTP.',
    loginTotpManualStep2: 'Abra la aplicación e ingrese la clave:',
    loginTotpManualStep3: 'Utilice los siguientes valores de configuración si la aplicación permite configurarlos:',
    loginTotpUnableToScan: '¿No se puede escanear?',
    loginTotpScanBarcode: '¿Escanear código de barras?',
    loginCredential: 'Credencial',
    loginOtpOneTime: 'Stakkota',
    loginTotpType: 'Tipo',
    loginTotpAlgorithm: 'Algoritmo',
    loginTotpDigits: 'Dígitos',
    loginTotpInterval: 'Intervalo',
    loginTotpCounter: 'Mostrador',
    loginTotpDeviceName: 'Nombre del dispositivo',
    'loginTotp.totp': 'Basado en el tiempo',
    'loginTotp.hotp': 'Basado en contador',
    totpAppFreeOTPName: 'GratisOTP',
    totpAppGoogleName: 'Autenticador de Google',
    loginChooseAuthenticator: 'Seleccione el método de inicio de sesión',
    oauthGrantRequest: '¿Otorga estos privilegios de acceso?',
    inResource: 'en',
    oauth2DeviceVerificationTitle: 'Inicio de sesión del dispositivo',
    verifyOAuth2DeviceUserCode: 'Ingrese el código proporcionado por su dispositivo y haga clic en Enviar',
    oauth2DeviceInvalidUserCodeMessage: 'Código no válido, por favor vuelva a intentarlo.',
    oauth2DeviceExpiredUserCodeMessage: 'El código ha caducado. Vuelva a su dispositivo e intente conectarse nuevamente.',
    oauth2DeviceVerificationCompleteHeader: 'Inicio de sesión exitoso en el dispositivo',
    oauth2DeviceVerificationCompleteMessage: 'Puede cerrar esta ventana del navegador y volver a su dispositivo.',
    oauth2DeviceVerificationFailedHeader: 'Error al iniciar sesión en el dispositivo',
    oauth2DeviceVerificationFailedMessage: 'Puede cerrar esta ventana del navegador, volver a su dispositivo e intentar conectarse nuevamente.',
    oauth2DeviceConsentDeniedMessage: 'Consentimiento denegado para conectar el dispositivo.',
    oauth2DeviceAuthorizationGrantDisabledMessage: 'El cliente no puede iniciar la concesión de autorización de dispositivo OAuth 2.0. El flujo está deshabilitado para el cliente.',
    emailVerifyInstruction1: 'Se ha enviado un correo electrónico a su dirección {0} con instrucciones para verificar su dirección de correo electrónico.',
    emailVerifyInstruction2: '¿No ha recibido un código de verificación en su correo electrónico?',
    emailVerifyInstruction3: 'para volver a enviar el correo electrónico.',
    emailLinkIdpTitle: 'Enlace {0}',
    emailLinkIdp1: 'Se le ha enviado un correo electrónico con instrucciones para vincular la {0} cuenta {1} con su cuenta {2}.',
    emailLinkIdp2: 'Si ya verificó el correo electrónico en un navegador diferente',
    emailLinkIdp3: 'para volver a enviar el correo electrónico.',
    emailLinkIdp4: 'Si ya verificó el correo electrónico en un navegador diferente',
    emailLinkIdp5: 'para continuar.',
    backToLogin: '&laquo; Atrás para iniciar sesión',
    emailInstruction: 'Ingrese su nombre de usuario o dirección de correo electrónico y le enviaremos instrucciones sobre cómo crear una nueva contraseña.',
    emailInstructionUsername: 'Ingrese su nombre de usuario y le enviaremos instrucciones sobre cómo crear una nueva contraseña.',
    copyCodeInstruction: 'Copie este código y péguelo en su aplicación:',
    pageExpiredTitle: 'La página ha caducado',
    pageExpiredMsg1: 'Para reiniciar el proceso de inicio de sesión',
    pageExpiredMsg2: 'Para continuar el proceso de inicio de sesión',
    personalInfo: 'Información personal:',
    role_admin: 'Administración',
    'role_realm-admin': 'Administrador de reino',
    'role_create-realm': 'Crear reino',
    'role_create-client': 'Crear cliente',
    'role_view-realm': 'Ver reino',
    'role_view-users': 'Ver usuarios',
    'role_view-applications': 'Ver aplicaciones',
    'role_view-clients': 'Ver clientes',
    'role_view-events': 'Ver eventos',
    'role_view-identity-providers': 'Ver proveedores de identidad',
    'role_manage-realm': 'Administrar reino',
    'role_manage-users': 'Administrar usuarios',
    'role_manage-applications': 'Administrar aplicaciones',
    'role_manage-identity-providers': 'Administrar proveedores de identidad',
    'role_manage-clients': 'Administrar clientes',
    'role_manage-events': 'Administrar eventos',
    'role_view-profile': 'Ver perfil',
    'role_manage-account': 'Administrar cuenta',
    'role_manage-account-links': 'Administrar enlaces de cuentas',
    'role_read-token': 'Leer ficha',
    'role_offline-access': 'Acceso sin conexión',
    client_account: 'Cuenta',
    'client_account-console': 'Consola de cuenta',
    'client_security-admin-console': 'Consola de administración de seguridad',
    'client_admin-cli': 'CLI de administrador',
    'client_realm-management': 'Gestión de reinos',
    client_broker: 'Corredor',
    requiredFields: 'Campos requeridos',
    invalidUserMessage: 'Usuario o contraseña invalido.',
    invalidUsernameMessage: 'Nombre de usuario no válido.',
    invalidUsernameOrEmailMessage: 'Nombre de usuario o correo electrónico no válido.',
    invalidPasswordMessage: 'Contraseña invalida.',
    invalidEmailMessage: 'Dirección de correo electrónico no válida.',
    accountDisabledMessage: 'La cuenta está deshabilitada, comuníquese con su administrador.',
    accountTemporarilyDisabledMessage: 'La cuenta está temporalmente deshabilitada; póngase en contacto con su administrador o vuelva a intentarlo más tarde.',
    expiredCodeMessage: 'Se terminó el tiempo para la autenticación. Inicie sesión de nuevo.',
    expiredActionMessage: 'La acción expiró. Continúe iniciando sesión ahora.',
    expiredActionTokenNoSessionMessage: 'La acción expiró.',
    expiredActionTokenSessionExistsMessage: 'La acción expiró. Por favor, empiece de nuevo.',
    sessionLimitExceeded: 'Hay demasiadas sesiones',
    missingFirstNameMessage: 'Por favor especifique el nombre.',
    missingLastNameMessage: 'Por favor especifique el apellido.',
    missingEmailMessage: 'Por favor especifique el correo electrónico.',
    missingUsernameMessage: 'Por favor especifique el nombre de usuario.',
    missingPasswordMessage: 'Por favor especifique la contraseña.',
    missingTotpMessage: 'Especifique el código de autenticación.',
    missingTotpDeviceNameMessage: 'Por favor especifique el nombre del dispositivo.',
    notMatchPasswordMessage: 'Las contraseñas no coinciden.',
    'error-invalid-value': 'Valor no válido.',
    'error-invalid-blank': 'Por favor especifique el valor.',
    'error-empty': 'Por favor especifique el valor.',
    'error-invalid-length': 'La longitud debe estar entre {1} y {2}.',
    'error-invalid-length-too-short': 'La longitud mínima es {1}.',
    'error-invalid-length-too-long': 'La longitud máxima es {2}.',
    'error-invalid-email': 'Número inválido',
    'error-invalid-number': 'Número inválido',
    'error-number-out-of-range': 'El número debe estar entre {1} y {2}.',
    'error-number-out-of-range-too-small': 'El número debe tener un valor mínimo de {1}.',
    'error-number-out-of-range-too-big': 'El número debe tener un valor máximo de {2}.',
    'error-pattern-no-match': 'Valor no válido.',
    'error-invalid-uri': 'URL invalida.',
    'error-invalid-uri-scheme': 'Esquema de URL no válido.',
    'error-invalid-uri-fragment': 'Fragmento de URL no válido.',
    'error-user-attribute-required': 'Por favor especifique este campo.',
    'error-invalid-date': 'Fecha invalida.',
    'error-user-attribute-read-only': 'Este campo es de sólo lectura.',
    'error-username-invalid-character': 'El valor contiene un carácter no válido.',
    'error-person-name-invalid-character': 'El valor contiene un carácter no válido.',
    invalidPasswordExistingMessage: 'Contraseña existente no válida.',
    invalidPasswordBlacklistedMessage: 'Contraseña no válida: la contraseña está en la lista negra.Ógildigt loyniorð: loyniorðið er sett á svartalista.',
    invalidPasswordConfirmMessage: 'La confirmación de la contraseña no coincide.',
    invalidTotpMessage: 'Código de autenticación no válido.',
    usernameExistsMessage: 'Nombre de usuario ya existe.',
    emailExistsMessage: 'El Email ya existe.',
    federatedIdentityExistsMessage: 'El usuario con {0} {1} ya existe. Inicie sesión en la administración de cuentas para vincular la cuenta.',
    federatedIdentityUnavailableMessage: 'El usuario {0} autenticado con el proveedor de identidad {1} no existe. Comuníquese con su administrador.',
    confirmLinkIdpTitle: 'La cuenta ya existe',
    federatedIdentityConfirmLinkMessage: 'El usuario con {0} {1} ya existe. ¿Cómo quiere continuar?',
    federatedIdentityConfirmReauthenticateMessage: 'Auteníquese para vincular su cuenta con {0}',
    nestedFirstBrokerFlowMessage: 'El {0} usuario {1} no está vinculado a ningún usuario conocido.',
    confirmLinkIdpReviewProfile: 'Revisar perfil',
    confirmLinkIdpContinue: 'Agregar a cuenta existente',
    configureTotpMessage: 'Debe configurar Mobile Authenticator para activar su cuenta.',
    configureBackupCodesMessage: 'Debe configurar códigos de respaldo para activar su cuenta.',
    updateProfileMessage: 'Necesita actualizar su perfil de usuario para activar su cuenta.',
    updatePasswordMessage: 'Necesita cambiar su contraseña para activar su cuenta.',
    updateEmailMessage: 'Debe actualizar su dirección de correo electrónico para activar su cuenta.',
    resetPasswordMessage: 'Necesita cambiar su contraseña.',
    verifyEmailMessage: 'Debe verificar su dirección de correo electrónico para activar su cuenta.',
    linkIdpMessage: 'Debe verificar su dirección de correo electrónico para vincular su cuenta con {0}.',
    emailSentMessage: 'Debería recibir un correo electrónico en breve con más instrucciones.',
    emailSendErrorMessage: 'No se pudo enviar el correo electrónico, inténtelo nuevamente más tarde.',
    accountUpdatedMessage: 'Su cuenta ha sido actualizada.',
    accountPasswordUpdatedMessage: 'Su contraseña ha sido actualizada.',
    delegationCompleteHeader: 'Inicio de sesión correcto',
    delegationCompleteMessage: 'Puede cerrar esta ventana del navegador y volver a la aplicación de su consola.',
    delegationFailedHeader: 'Error de inicio de sesion',
    delegationFailedMessage: 'Puede cerrar esta ventana del navegador, volver a la aplicación de su consola e intentar iniciar sesión nuevamente.',
    noAccessMessage: 'Sin acceso',
    invalidPasswordMinLengthMessage: 'Contraseña no válida: longitud mínima {0}.',
    invalidPasswordMaxLengthMessage: 'Contraseña no válida: longitud máxima {0}.',
    invalidPasswordMinDigitsMessage: 'Contraseña no válida: debe contener al menos {0} dígitos numéricos.',
    invalidPasswordMinLowerCaseCharsMessage: 'Contraseña no válida: debe contener al menos {0} caracteres en minúscula.',
    invalidPasswordMinUpperCaseCharsMessage: 'Contraseña no válida: debe contener al menos {0} caracteres en mayúscula.',
    invalidPasswordMinSpecialCharsMessage: 'Contraseña no válida: debe contener al menos {0} caracteres especiales.',
    invalidPasswordNotUsernameMessage: 'Contraseña no válida: no debe ser igual al nombre de usuario.',
    invalidPasswordNotEmailMessage: 'Contraseña no válida: no debe ser igual al correo electrónico.',
    invalidPasswordRegexPatternMessage: 'Contraseña no válida: no coincide con los patrones de expresiones regulares.',
    invalidPasswordHistoryMessage: 'Contraseña no válida: no debe ser igual a ninguna de las últimas {0} contraseñas.',
    invalidPasswordGenericMessage: 'Contraseña no válida: la nueva contraseña no coincide con las políticas de contraseñas.',
    failedToProcessResponseMessage: 'No se pudo procesar la respuesta',
    httpsRequiredMessage: 'Se requiere HTTPS',
    realmNotEnabledMessage: 'Reino no habilitado',
    invalidRequestMessage: 'Solicitud no válida',
    successLogout: 'Está desconectado',
    failedLogout: 'Error al cerrar sesión',
    unknownLoginRequesterMessage: 'Solicitante de inicio de sesión desconocido',
    loginRequesterNotEnabledMessage: 'Solicitante de inicio de sesión no habilitado',
    bearerOnlyMessage: 'Las aplicaciones exclusivas para portadores no pueden iniciar sesión en el navegador',
    standardFlowDisabledMessage: 'El cliente no puede iniciar sesión en el navegador con el tipo de respuesta determinado. El flujo estándar está deshabilitado para el cliente.',
    implicitFlowDisabledMessage: 'El cliente no puede iniciar sesión en el navegador con el tipo de respuesta determinado. El flujo implícito está deshabilitado para el cliente.',
    invalidRedirectUriMessage: 'Uri de redireccionamiento no válido',
    unsupportedNameIdFormatMessage: 'Formato de ID de nombre no admitido',
    invalidRequesterMessage: 'Solicitante no válido',
    registrationNotAllowedMessage: 'Registro no permitido',
    resetCredentialNotAllowedMessage: 'Restablecer credencial no permitido',
    permissionNotApprovedMessage: 'Permiso no aprobado.',
    noRelayStateInResponseMessage: 'No hay estado de retransmisión en respuesta del proveedor de identidad.',
    insufficientPermissionMessage: 'Permisos insuficientes para vincular identidades.',
    couldNotProceedWithAuthenticationRequestMessage: 'No se pudo continuar con la solicitud de autenticación al proveedor de identidad.',
    couldNotObtainTokenMessage: 'No se pudo obtener el token del proveedor de identidad.',
    unexpectedErrorRetrievingTokenMessage: 'Error inesperado al recuperar el token del proveedor de identidad.',
    unexpectedErrorHandlingResponseMessage: 'Error inesperado al manejar la respuesta del proveedor de identidad.',
    identityProviderAuthenticationFailedMessage: 'La autenticación falló. No se pudo autenticar con el proveedor de identidad.',
    couldNotSendAuthenticationRequestMessage: 'No se pudo enviar la solicitud de autenticación al proveedor de identidad.',
    unexpectedErrorHandlingRequestMessage: 'Error inesperado al manejar la solicitud de autenticación al proveedor de identidad.',
    invalidAccessCodeMessage: 'Código de acceso inválido.',
    sessionNotActiveMessage: 'Sesión no activa.',
    invalidCodeMessage: 'Se produjo un error, inicie sesión nuevamente a través de su aplicación.',
    cookieNotFoundMessage: 'Cookie no encontrada. Asegúrese de que las cookies estén habilitadas en su navegador.',
    insufficientLevelOfAuthentication: 'El nivel de autenticación solicitado no se ha satisfecho.',
    identityProviderUnexpectedErrorMessage: 'Error inesperado al autenticarse con el proveedor de identidad',
    identityProviderMissingStateMessage: 'Falta parámetro de estado en respuesta del proveedor de identidad.',
    identityProviderInvalidResponseMessage: 'Respuesta no válida del proveedor de identidad.',
    identityProviderInvalidSignatureMessage: 'Firma no válida en respuesta del proveedor de identidad.',
    identityProviderNotFoundMessage: 'No se pudo encontrar un proveedor de identidad con el identificador.',
    identityProviderLinkSuccess: 'Verificó exitosamente su correo electrónico. Vuelva a su navegador original y continúe allí con el inicio de sesión.',
    staleCodeMessage: 'Esta página ya no es válida, regrese a su solicitud e inicie sesión nuevamente',
    realmSupportsNoCredentialsMessage: 'Reino no admite ningún tipo de credencial.',
    credentialSetupRequired: 'No puedo iniciar sesión, se requiere configuración de credenciales.',
    identityProviderNotUniqueMessage: 'Reino admite múltiples proveedores de identidad. No se pudo determinar con qué proveedor de identidad se debe utilizar para autenticar.',
    emailVerifiedMessage: 'Su dirección de email ha sido verificada.',
    staleEmailVerificationLink: 'El enlace en el que hizo clic es un enlace obsoleto y ya no es válido. Quizás ya haya verificado su correo electrónico.',
    identityProviderAlreadyLinkedMessage: 'La identidad federada devuelta por {0} ya está vinculada a otro usuario.',
    confirmAccountLinking: 'Confirme la vinculación de la cuenta {0} del proveedor de identidad {1} con su cuenta.',
    confirmEmailAddressVerification: 'Confirme la validez de la dirección de correo electrónico {0}.',
    confirmExecutionOfActions: 'Realice las siguientes acciones',
    locale_ca: 'Català',
    locale_cs: 'Čeština',
    locale_da: 'Alemán',
    locale_de: 'Alemán',
    locale_en: 'Inglés',
    locale_es: 'Español',
    locale_fr: 'Français',
    locale_hu: 'Magyar',
    locale_it: 'Italiano',
    locale_ja: '日本語',
    locale_lt: 'Lietuvių',
    locale_lv: 'Latviešu',
    locale_nl: 'Nederlands',
    locale_no: 'Norsk',
    locale_pl: 'Polski',
    locale_pt_BR: 'Português (Brasil)',
    locale_ru: 'Русский',
    locale_sk: 'Slovenčina',
    locale_sv: 'Svenska',
    locale_tr: 'Türkçe',
    'locale_zh-CN': '中文简体',
    locale_fi: 'Suomi',
    backToApplication: '&laquo; Volver a la aplicación',
    missingParameterMessage: 'Parámetros faltantes: {0}',
    clientNotFoundMessage: 'Cliente no encontrado.',
    clientDisabledMessage: 'Cliente deshabilitado.',
    invalidParameterMessage: 'Parametro inválido: {0}',
    alreadyLoggedIn: 'Ya se ha autentificado.',
    differentUserAuthenticated: "Ya está autenticado como usuario diferente ''{0}'' en esta sesión. Por favor cierre sesión primero.",
    brokerLinkingSessionExpired: 'Se solicitó la vinculación de la cuenta del corredor, pero la sesión actual ya no es válida.',
    proceedWithAction: '&raquo; Haga click aquí para proceder',
    acrNotFulfilled: 'Requisitos de autenticación no cumplidos',
    'requiredAction.CONFIGURE_TOTP': 'Configurar OTP',
    'requiredAction.terms_and_conditions': 'Términos y Condiciones',
    'requiredAction.UPDATE_PASSWORD': 'Actualizar contraseña',
    'requiredAction.UPDATE_PROFILE': 'Actualización del perfil',
    'requiredAction.VERIFY_EMAIL': 'Verificar correo electrónico',
    'requiredAction.CONFIGURE_RECOVERY_AUTHN_CODES': 'Generar códigos de recuperación',
    'requiredAction.webauthn-register-passwordless': 'Registro de Webautn sin contraseña',
    doX509Login: 'Iniciará sesión como:',
    clientCertificate: 'Certificado de cliente X509:',
    noCertificate: '[Sin certificado]',
    pageNotFound: 'Página no encontrada',
    internalServerError: 'Se ha producido un error interno del servidor',
    'console-username': 'Nombre de usuario:',
    'console-password': 'Contraseña:',
    'console-otp': 'Contraseña de un solo uso:',
    'console-new-password': 'Nueva contraseña:',
    'console-confirm-password': 'Confirmar contraseña:',
    'console-update-password': 'Se requiere la actualización de su contraseña.',
    'console-verify-email': 'Necesita verificar su dirección de correo electrónico. Enviamos un correo electrónico a {0} que contiene un código de verificación.  Por favor ingrese este código en la entrada a continuación.',
    'console-email-code': 'Código de correo electrónico:',
    'console-accept-terms': '¿Aceptar los terminos? [s/n]:',
    'console-accept': 's',
    'openshift.scope.user_info': 'Informacion del usuario',
    'openshift.scope.user_check-access': 'Información de acceso del usuario',
    'openshift.scope.user_full': 'Acceso completo',
    'openshift.scope.list-projects': 'Listar proyectos',
    'saml.post-form.title': 'Redirección de autenticación',
    'saml.post-form.message': 'Redireccionando, Por favor espere.',
    'saml.post-form.js-disabled': 'JavaScript está deshabilitado. Recomendamos encarecidamente habilitarlo. Haga clic en el botón de abajo para continuar. ',
    'saml.artifactResolutionServiceInvalidResponse': 'No se puede resolver el artefacto.',
    'otp-display-name': 'Aplicación de autenticación',
    'otp-help-text': 'Ingrese un código de verificación de la aplicación de autenticación.',
    'password-display-name': 'Inicie sesión ingresando su contraseña.',
    'password-help-text': 'Comience a iniciar sesión ingresando su nombre de usuario',
    'auth-username-form-display-name': 'Nombre de usuario y contraseña',
    'auth-username-form-help-text': 'Inicie sesión ingresando su nombre de usuario y contraseña.',
    'auth-username-password-form-display-name': 'Nombre de usuario y contraseña',
    'auth-username-password-form-help-text': 'Inicie sesión ingresando su nombre de usuario y contraseña.',
    'auth-recovery-authn-code-form-display-name': 'Código de autenticación de recuperación',
    'auth-recovery-authn-code-form-help-text': 'Ingrese un código de autenticación de recuperación de una lista generada previamente.',
    'auth-recovery-code-info-message': 'Ingrese el código de recuperación especificado.',
    'auth-recovery-code-prompt': 'Código de recuperación #{0}',
    'auth-recovery-code-header': 'Inicie sesión con un código de autenticación de recuperación',
    'recovery-codes-error-invalid': 'Código de autenticación de recuperación no válido',
    'recovery-code-config-header': 'Códigos de autenticación de recuperación',
    'recovery-code-config-warning-title': 'Estos códigos de recuperación no volverán a aparecer después de salir de esta página',
    'recovery-code-config-warning-message': 'Asegúrese de imprimirlos, descargarlos o copiarlos en un administrador de contraseñas y guardarlos. Cancelar esta configuración eliminará estos códigos de recuperación de su cuenta.',
    'recovery-codes-print': 'Imprimir',
    'recovery-codes-download': 'Descargar',
    'recovery-codes-copy': 'Copia',
    'recovery-codes-copied': 'Copiado',
    'recovery-codes-confirmation-message': 'He guardado estos códigos en algún lugar seguro.',
    'recovery-codes-action-complete': 'Configuración completa',
    'recovery-codes-action-cancel': 'Cancelar configuración',
    'recovery-codes-download-file-header': 'Guarde estos códigos de recuperación en un lugar seguro.',
    'recovery-codes-download-file-description': 'Los códigos de recuperación son códigos de acceso de un solo uso que le permiten iniciar sesión en su cuenta si no tiene acceso a su autenticador.',
    'recovery-codes-download-file-date': ' Estos códigos fueron generados en',
    'recovery-codes-label-default': 'Códigos de recuperación',
    'webauthn-display-name': 'Clave de seguridad',
    'webauthn-help-text': 'Utilice su clave de seguridad para iniciar sesión.',
    'webauthn-passwordless-display-name': 'Utilice su clave de seguridad para iniciar sesión sin contraseña.',
    'webauthn-passwordless-help-text': 'Utilice su clave de seguridad para iniciar sesión sin contraseña.',
    'webauthn-login-title': 'Inicio de sesión con clave de seguridad',
    'webauthn-registration-title': 'Registro de clave de seguridad',
    'webauthn-available-authenticators': 'Claves de seguridad disponibles',
    'webauthn-unsupported-browser-text': 'WebAuthn no es compatible con este navegador. Pruebe con otro o comuníquese con su administrador.',
    'webauthn-doAuthenticate': 'Iniciar sesión con clave de seguridad',
    'webauthn-createdAt-label': 'Creado',
    'webauthn-error-title': 'Error de clave de seguridad',
    'webauthn-error-registration': 'No se pudo registrar su clave de seguridad.<br/> {0}',
    'webauthn-error-api-get': 'No se pudo autenticar mediante la clave de seguridad.<br/> {0}',
    'webauthn-error-different-user': 'El primer usuario autenticado no es el que está autenticado por la clave de seguridad.',
    'webauthn-error-auth-verification': 'El resultado de la autenticación de la clave de seguridad no es válido.<br/> {0}<a',
    'webauthn-error-register-verification': 'El resultado del registro de la clave de seguridad no es válido.<br/> {0}',
    'webauthn-error-user-not-found': 'Usuario desconocido autenticado por la clave de seguridad.',
    'identity-provider-redirector': 'Conéctese con otro proveedor de identidad',
    'identity-provider-login-label': 'O inicie sesión con',
    'idp-email-verification-display-name': 'Vincule su cuenta validando su correo electrónico.',
    'idp-email-verification-help-text': 'Vincule su cuenta iniciando sesión.',
    'idp-username-password-form-display-name': 'Nombre de usuario y contraseña',
    'idp-username-password-form-help-text': 'Inicie sesión ingresando su nombre de usuario y contraseña.',
    finalDeletionConfirmation: 'Si elimina su cuenta, no podrá restaurarla. Para conservar su cuenta, haga clic en Cancelar.',
    irreversibleAction: 'Esta acción es irreversible.',
    deleteAccountConfirm: 'Eliminar confirmación de cuenta',
    deletingImplies: 'Eliminar su cuenta implica:',
    errasingData: 'Borrar todos tus datos',
    loggingOutImmediately: 'Cerrar sesión inmediatamente',
    accountUnusable: 'Cualquier uso posterior de la aplicación no será posible con esta cuenta.',
    userDeletedSuccessfully: 'Usuario eliminado exitosamente',
    'access-denied': 'Acceso denegado',
    'frontchannel-logout.title': 'Salir de su cuenta',
    'frontchannel-logout.message': 'Está cerrando sesión en las siguientes aplicaciones',
    logoutConfirmTitle: '¿Quiere cerrar sesión?',
    logoutConfirmHeader: '¿Quiere cerrar sesión?',
    doLogout: 'Cerrar sesión',
    readOnlyUsernameMessage: 'No puede actualizar su nombre de usuario ya que es de sólo lectura.',
    /* spell-checker: enable */
  },
}
